/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core'
import { Amplify } from 'aws-amplify'
import {
	getCurrentUser,
	signOut,
	fetchAuthSession,
	fetchUserAttributes,
	FetchUserAttributesOutput,
} from 'aws-amplify/auth'
import { AmplifyConfig } from '@core/config/auth.config'

/**
 * Servicio que contiene la lógica de autenticación con AWS Amplify
 */
@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor() {
		Amplify.configure({
			Auth: AmplifyConfig,
		})
	}

	// Verifica si el usuario está autenticado con AWS Amplify
	isAuthenticated(): Promise<boolean> {
		return getCurrentUser()
			.then(() => true)
			.catch(() => false)
	}

	// Refresca la sesión del usuario
	refreshSession(): Promise<boolean> {
		return fetchAuthSession({ forceRefresh: true })
			.then(() => true)
			.catch(() => false)
	}

	// Obtiene la fecha de expiración de la sesión del usuario
	async getSessionExpiration(): Promise<number> {
		const { accessToken } = (await fetchAuthSession()).tokens ?? {} // Se obtiene el token de acceso

		if (accessToken?.payload.exp) {
			return accessToken.payload.exp
		}

		return 0
	}

	getUserAttributes(): Promise<FetchUserAttributesOutput> {
		return fetchUserAttributes()
	}

	// Cierra la sesión del usuario
	signOut(): Promise<void> {
		return signOut()
	}

	async getIdToken(): Promise<string> {
		const { idToken } = (await fetchAuthSession()).tokens ?? {} // Se obtiene el token de acceso

		return idToken?.toString() ?? ''
	}
}
